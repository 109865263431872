@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

body {
  font-family: var(--font-primary), sans-serif, system-ui, -apple-system;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 20 14.3% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 20 14.3% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 20 14.3% 4.1%;
    --primary: 24.6 95% 53.1%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 60 4.8% 95.9%;
    --secondary-foreground: 24 9.8% 10%;
    --muted: 60 4.8% 95.9%;
    --muted-foreground: 25 5.3% 44.7%;
    --accent: 60 4.8% 95.9%;
    --accent-foreground: 24 9.8% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 20 5.9% 90%;
    --input: 20 5.9% 90%;
    --ring: 24.6 95% 53.1%;
    --radius: 0rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --honk-1: 60 50% 55%; /* Richer Muted Yellow for Dark Mode */
    --honk-2: 41 45% 50%; /* Warmer Muted Orange */
    --honk-3: 350 55% 50%; /* Richer Pink */
    --honk-4: 322 50% 50%; /* Balanced Magenta */
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 60 9.1% 97.8%;
    --card: 20 14.3% 4.1%;
    --card-foreground: 60 9.1% 97.8%;
    --popover: 20 14.3% 4.1%;
    --popover-foreground: 60 9.1% 97.8%;
    --primary: 20.5 90.2% 48.2%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 12 6.5% 15.1%;
    --secondary-foreground: 60 9.1% 97.8%;
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 60 9.1% 97.8%;
    --destructive: 0 72.2% 50.6%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 12 6.5% 15.1%;
    --input: 12 6.5% 15.1%;
    --ring: 20.5 90.2% 48.2%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --honk-1: 60 50% 55%; /* Richer Muted Yellow for Dark Mode */
    --honk-2: 41 45% 50%; /* Warmer Muted Orange */
    --honk-3: 350 55% 50%; /* Richer Pink */
    --honk-4: 322 50% 50%; /* Balanced Magenta */
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

@layer base {
  .font-honk {
    font-variation-settings: "MORF" 15, "SHLN" 85 !important;
    @apply hover:animate-glare;
  }

  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-background;
  }

  ul {
    @apply list-disc list-outside;
  }

  li {
    @apply ml-6;
  }

  .honk-gradient-1 {
    background: hsl(var(--honk-1)); /* Fallback for non-gradient support */
    background: linear-gradient(
      0deg,
      hsl(var(--honk-1)) 0%,
      hsl(var(--honk-2)) 33%,
      hsl(var(--honk-3)) 67%,
      hsl(var(--honk-4)) 100%
    );
  }

  .anti-gradient {
    background: #000; /* Fallback for non-gradient support */
    background: linear-gradient(
      0deg,
      hsl(var(--muted-foreground)) 0%,
      hsl(var(--background)) 100%
    );
  }

  .honk-gradient-2 {
    background: hsl(var(--honk-1)); /* Fallback for non-gradient support */
    background: linear-gradient(
      90deg,
      hsl(var(--honk-1)) 0%,
      hsl(var(--honk-2)) 33%,
      hsl(var(--honk-3)) 67%,
      hsl(var(--honk-4)) 100%
    );
  }

  .honk-gradient-3 {
    background: hsl(var(--honk-1)); /* Fallback for non-gradient support */
    background: linear-gradient(
      180deg,
      hsl(var(--honk-1)) 0%,
      hsl(var(--honk-2)) 33%,
      hsl(var(--honk-3)) 67%,
      hsl(var(--honk-4)) 100%
    );
  }

  .honk-gradient-4 {
    background: hsl(var(--honk-1)); /* Fallback for non-gradient support */
    background: linear-gradient(
      270deg,
      hsl(var(--honk-1)) 0%,
      hsl(var(--honk-2)) 33%,
      hsl(var(--honk-3)) 67%,
      hsl(var(--honk-4)) 100%
    );
  }

  .text-honk-gradient {
    background: linear-gradient(
      0deg,
      hsl(var(--honk-1)) 0%,
      hsl(var(--honk-2)) 33%,
      hsl(var(--honk-3)) 67%,
      hsl(var(--honk-4)) 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent; /* Make the text transparent to show the background */
  }

  h1 {
    @apply text-2xl md:text-4xl font-bold w-fit;
  }

  h2 {
    @apply text-xl md:text-3xl font-bold w-fit;
  }

  h3 {
    @apply text-xl md:text-2xl font-bold;
  }

  p {
    @apply text-lg md:text-xl;
  }

  section {
    @apply w-full flex flex-col gap-10 bg-transparent h-fit;
  }
  article {
    @apply bg-foreground/100 m-2 md:m-10 p-2;
  }
}

@layer utilities {
  a,
  button {
    @apply !cursor-pointer;
  }

  .w-webkit-fill {
    width: -webkit-fill-available;
  }
  .w-moz-available {
    width: -moz-available;
  }

  .h-webkit-fill {
    height: -webkit-fill-available;
  }
  .h-moz-available {
    height: -moz-available;
  }
  .scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .font-outline {
    color: white;
    text-shadow: -2px -2px 5 #000, 2px -2px 5 #000, -2px 2px 5 #000,
      2px 2px 5 #000;
  }
}

.no-visible-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

.no-visible-scrollbar::-webkit-scrollbar {
  display: none;
}
